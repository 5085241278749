import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
const gaId = process.env.REACT_APP_GA_ID ?? false;

const GoogleAnalytics = ({ cookieState }) => {
  if (gaId) {
    ReactGA.initialize(gaId);
  }

  let location = useLocation();

  useEffect(() => {
    if (gaId && cookieState) {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [location, cookieState]);

  return null;
};
export default GoogleAnalytics;
