import React, { lazy, Suspense, useState, useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import customHistory from "./history";
import { ReactComponent as Icons } from "./assets/icons.svg";

import Header from "./components/Header/Header";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import GoogleAnalytics from "./components/GoogleAnalytics";
import CookieBannerGDPR from "./components/CookieBannerGDPR";
import Routing from "./Routing";
import ScrollToTop from "./utils/ScrollToTop";

const Home = lazy(() => import("./components/Home"));
const SearchResults = lazy(() => import("./components/SearchResults"));

const App = () => {
  const [statisticsCookieState, setStatisticsCookieState] = useState(false);
  const [marketingCookieState, setMarketingCookieState] = useState(false);

  // Standardmäßige Consent-Werte setzen
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag("consent", "default", {
      analytics_storage: "denied",
      ad_storage: "denied",
      ad_user_data: "denied",
      ad_personalization: "denied",
      wait_for_update: 500,
    });
  }, []);

  const updateConsent = (consent) => {
    setStatisticsCookieState((prev) =>
      consent.analytics !== undefined ? consent.analytics : prev
    );
    setMarketingCookieState((prev) =>
      consent.marketing !== undefined ? consent.marketing : prev
    );

    let updatedConsent = {
      analytics_storage:
        consent.analytics !== undefined
          ? consent.analytics
            ? "granted"
            : "denied"
          : statisticsCookieState
          ? "granted"
          : "denied",
      ad_storage:
        consent.marketing !== undefined
          ? consent.marketing
            ? "granted"
            : "denied"
          : marketingCookieState
          ? "granted"
          : "denied",
      ad_user_data:
        consent.marketing !== undefined
          ? consent.marketing
            ? "granted"
            : "denied"
          : marketingCookieState
          ? "granted"
          : "denied",
      ad_personalization:
        consent.marketing !== undefined
          ? consent.marketing
            ? "granted"
            : "denied"
          : marketingCookieState
          ? "granted"
          : "denied",
    };

    setTimeout(() => {
      if (!window.dataLayer) {
        window.dataLayer = [];
      }

      window.dataLayer.push({
        event: "consent_update",
        consent: updatedConsent,
      });
    }, 0);
  };

  return (
    <main>
      <Router history={customHistory}>
        <ScrollToTop />
        <React.Fragment>
          <Icons style={{ display: "none" }} />
          <div className="main" id="top">
            <Header />
            <GoogleAnalytics cookieState={statisticsCookieState} />
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route
                  path="/suche/:suchanfrage"
                  render={(props) => <SearchResults {...props} />}
                />
                <Route path="/" render={(props) => <Routing {...props} />} />{" "}
              </Switch>
            </Suspense>
            <Footer cookieState={marketingCookieState} />
          </div>
        </React.Fragment>
      </Router>

      <CookieBannerGDPR onConsentUpdate={updateConsent} />
    </main>
  );
};

export default App;
